






















































































































.huaiHuaInfo-page {
  .huaiHuaInfo-content{
    max-width: 1060px;
    min-height: 500px;
    margin: 30px auto 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
      .ListNav {
        width: 18%;
        margin-right: 1%;
        color: #fff;
        min-height: 250px;
        border-radius: 10px;
        // background-position: center;
        background-image: url("../assets/humanities/leftMemuBg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        .title{
          text-align: center;
          font-size: 22px;
          font-family: emoji;
          color: #fff;
          border-bottom: 8px #FFF solid;
          padding: 10px 10px 15px 10px;
        }
        .itemWrapper {
          box-sizing: border-box;
          padding: 0px 10px 20px 10px;
          width: 100%;
          div {
            margin: 20px 5px;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            padding: 12px 5px;
            font-size: 15px;
            color: #b0c2ee;
            cursor: pointer;
            &:hover {
              background-color: rgb(135, 135, 243);
            }

          }
          .active {
            background-color: #FFF;
            border-bottom: 0.33rem solid #fbde00;
            color: black;
          }
        }
      }
      .article{
          width:70%;
          .title{
              font-size: 30px;
              font-weight: 600;
              color: #0066b3;
              text-align: center;
              padding: 0 32px 16px;
          }
          .subTitle{
              padding: 0 10px;
              font-size: 14px;
              font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
              text-align: center;
              margin-bottom: 30px;
          }
          .content{
            color: #3d3d3d;
            line-height: 34px;
            text-indent: 2em;
            margin-top: 15px;
          }
      }
  }
      

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
