.huaiHuaInfo-page .huaiHuaInfo-content {
  max-width: 1060px;
  min-height: 500px;
  margin: 30px auto 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.huaiHuaInfo-page .huaiHuaInfo-content .ListNav {
  width: 18%;
  margin-right: 1%;
  color: #fff;
  min-height: 250px;
  border-radius: 10px;
  background-image: url("../assets/humanities/leftMemuBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.huaiHuaInfo-page .huaiHuaInfo-content .ListNav .title {
  text-align: center;
  font-size: 22px;
  font-family: emoji;
  color: #fff;
  border-bottom: 8px #FFF solid;
  padding: 10px 10px 15px 10px;
}
.huaiHuaInfo-page .huaiHuaInfo-content .ListNav .itemWrapper {
  box-sizing: border-box;
  padding: 0px 10px 20px 10px;
  width: 100%;
}
.huaiHuaInfo-page .huaiHuaInfo-content .ListNav .itemWrapper div {
  margin: 20px 5px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 12px 5px;
  font-size: 15px;
  color: #b0c2ee;
  cursor: pointer;
}
.huaiHuaInfo-page .huaiHuaInfo-content .ListNav .itemWrapper div:hover {
  background-color: #8787f3;
}
.huaiHuaInfo-page .huaiHuaInfo-content .ListNav .itemWrapper .active {
  background-color: #FFF;
  border-bottom: 0.33rem solid #fbde00;
  color: black;
}
.huaiHuaInfo-page .huaiHuaInfo-content .article {
  width: 70%;
}
.huaiHuaInfo-page .huaiHuaInfo-content .article .title {
  font-size: 30px;
  font-weight: 600;
  color: #0066b3;
  text-align: center;
  padding: 0 32px 16px;
}
.huaiHuaInfo-page .huaiHuaInfo-content .article .subTitle {
  padding: 0 10px;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  margin-bottom: 30px;
}
.huaiHuaInfo-page .huaiHuaInfo-content .article .content {
  color: #3d3d3d;
  line-height: 34px;
  text-indent: 2em;
  margin-top: 15px;
}
.huaiHuaInfo-page .fade-enter-active,
.huaiHuaInfo-page .fade-leave-active {
  transition: opacity 0.5s;
}
.huaiHuaInfo-page .fade-enter,
.huaiHuaInfo-page .fade-leave-to {
  opacity: 0;
}
